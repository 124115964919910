<template>
  <v-card class="mt-5">
    <v-data-table
      :items="filteredProducts"
      :headers="headers"
      dense
      :items-per-page="100"
      group-by="category"
      :search="search"
    >
      <!--Search Bar + Filters-->
      <template v-slot:top>
        <v-row>
          <!--Filter-->
          <v-col md="4">
            <v-autocomplete
              v-model="categoriesFilter"
              :items="filteredCategories"
              outlined
              dense
              placeholder="Filter by category..."
              append-icon="mdi-filter-variant"
              clearable
            ></v-autocomplete>
          </v-col>
          <!--Search Bar-->
          <v-col md="8">
            <v-text-field
              v-model="search"
              clearable
              outlined
              dense
              placeholder="Search..."
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <!--Group header-->
      <template v-slot:group.header="{ items }">
        <th colspan="9" class="blue-grey lighten-5">
          {{ items[0].category }} ({{ items.length }})
        </th>
      </template>
      <!--Featured Image-->
      <template v-slot:item.images="{ item }">
        <v-img
          v-if="item.images.length !== 0"
          class="my-2"
          :src="item.images[0].url"
          :lazy-src="item.images[0].url"
          max-width="170px"
          width="170px"
        ></v-img>
        <!--Fallback image-->
        <v-img
          v-else
          class="my-2"
          src="../../../../assets/fallback-img.svg"
          lazy-src="../../../../assets/fallback-img.svg"
          max-width="170px"
          width="170px"
        >
          <v-overlay absolute color="#fff">
            <v-icon color="#46434f">mdi-file-image</v-icon>
          </v-overlay>
        </v-img>
      </template>
      <!--Description-->
      <template v-slot:item.description="{ item }">
        {{ truncate(item.description) }}
      </template>
      <!--Action Menu-->
      <template v-slot:item.id="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small icon v-bind="attrs" v-on="on"
              ><v-icon>mdi-dots-vertical</v-icon></v-btn
            >
          </template>
          <v-list>
            <v-list-item
              v-for="(el, index) in menuItems"
              :key="index"
              @click="el.action(item)"
            >
              <v-list-item-icon>
                <v-icon small left v-text="el.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ el.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import store from "../../store";
import { mapState, mapGetters } from "vuex";
export default {
  name: "ProductTable",
  data() {
    return {
      search: "",
      categoriesFilter: "",
      headers: [
        { text: "Featured Image", value: "images", align: "start" },
        { text: "Name", value: "name" },
        { text: "Category", value: "category" },
        { text: "Description", value: "description" },
        { text: "Actions", value: "id", align: "right" },
      ],
      menuItems: [
        {
          title: "Edit Product",
          icon: "mdi-pencil",
          action: (el) =>
            this.$router.push({
              name: "ecommerce-edit-product",
              params: { id: el.id },
            }),
        },
      ],
    };
  },
  store,
  computed: {
    ...mapState(["products"]),
    ...mapGetters(["filteredCategories"]),
    filteredProducts() {
      const query = this.categoriesFilter === "" || !this.categoriesFilter;
      return query
        ? this.products
        : this.products.filter((el) => el.category === this.categoriesFilter);
    },
  },
  methods: {
    //
    truncate(item) {
      if (!item) return "";
      // Strips HTML
      item = item.replace(/<[^>]+>/g, "");
      return !item || item.length < 50 ? item : `${item.slice(0, 50)}...`;
    },
  },
};
</script>