var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5"},[_c('v-data-table',{attrs:{"items":_vm.filteredProducts,"headers":_vm.headers,"dense":"","items-per-page":100,"group-by":"category","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.filteredCategories,"outlined":"","dense":"","placeholder":"Filter by category...","append-icon":"mdi-filter-variant","clearable":""},model:{value:(_vm.categoriesFilter),callback:function ($$v) {_vm.categoriesFilter=$$v},expression:"categoriesFilter"}})],1),_c('v-col',{attrs:{"md":"8"}},[_c('v-text-field',{attrs:{"clearable":"","outlined":"","dense":"","placeholder":"Search...","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"group.header",fn:function(ref){
var items = ref.items;
return [_c('th',{staticClass:"blue-grey lighten-5",attrs:{"colspan":"9"}},[_vm._v(" "+_vm._s(items[0].category)+" ("+_vm._s(items.length)+") ")])]}},{key:"item.images",fn:function(ref){
var item = ref.item;
return [(item.images.length !== 0)?_c('v-img',{staticClass:"my-2",attrs:{"src":item.images[0].url,"lazy-src":item.images[0].url,"max-width":"170px","width":"170px"}}):_c('v-img',{staticClass:"my-2",attrs:{"src":require("../../../../assets/fallback-img.svg"),"lazy-src":require("../../../../assets/fallback-img.svg"),"max-width":"170px","width":"170px"}},[_c('v-overlay',{attrs:{"absolute":"","color":"#fff"}},[_c('v-icon',{attrs:{"color":"#46434f"}},[_vm._v("mdi-file-image")])],1)],1)]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.truncate(item.description))+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.menuItems),function(el,index){return _c('v-list-item',{key:index,on:{"click":function($event){return el.action(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","left":""},domProps:{"textContent":_vm._s(el.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(el.title))])],1)],1)}),1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }